import React from "react";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap'; 
import { Link } from "@StarberryUtils";
import ScrollContainer from 'react-indiana-drag-scroll'

const Breadcrumbs_Interior =(props) => { 
  let path_name = (props.location.pathname).split('/'); 
  return(
  <div className="breadcrumb-block  text-center">
     <Container>
       <Row>
        <Col lg={12}>

          <ScrollContainer className="scroll-container">
            <Breadcrumb>
              <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
              <li className="breadcrumb-item"><Link to={'/'+path_name[1]}>Interior Design in Qatar</Link></li>  
              <li className="breadcrumb-item"  >{props.Heading}</li>
            </Breadcrumb>
          </ScrollContainer>
        </Col>
        </Row>
     </Container>
       
   
  </div>
  )
}

export default Breadcrumbs_Interior;


import React, { useState, useEffect } from "react"
import PlayVideo from "../../Play/PlayVideo";
import Slider from "react-slick"

const InteriorBanner = ({ Banner_Images, Video_URL }) => {

  const [isPlay, setPlay] = useState(false);
  // Slider
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    arrows: false,
    responsive: [
  
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true, 
          arrows: true,
        },
      } 
    ]
  };

  return (
    <React.Fragment>
      <div className="new-property-banner">
        <div className="col-img">
          {Banner_Images && <>
            <Slider {...settings} >
              {
                Banner_Images.map(sliderimg => {
                  return <img src={sliderimg.url} alt="" />
                })
              }
            </Slider>
          </>
          }
          {
            Video_URL &&
            <strong className="play-btn-icon" onClick={e => { setPlay(true) }}>
              <i className="icon-play-btn"></i>
            </strong>
          }
        </div>
        {
          Video_URL &&
          <PlayVideo
            isOpen={isPlay}
            isCloseFunction={setPlay}
            videoId={Video_URL}
            isAutoPlay={1}
          />
        }
      </div>
    </React.Fragment>
  )
}

export default InteriorBanner

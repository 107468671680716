
import React, { useState } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';

// Style
import './Gallery.scss';
import ScrollAnimation from 'react-animate-on-scroll';

const Gallery = ({ Gallery }) => {

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    var lightImages = Gallery && Gallery.map(img => img.url);
    var GalleryImages = Gallery && Gallery.slice(0,6);
    return (
        <div className="gallery">
            <Container>
                <h2>Gallery</h2>
                <Row >
                    {
                        GalleryImages && GalleryImages.length > 0 && GalleryImages.map((item, index) => {

                            return (
                                <Col md={6} lg={4} xl={4} sm={6} xs={6} className="gallery-block px-0" key={index}>
                                     <div className="animated">
                                        <div className="image" onClick={(e) => openLighboxMobile(e, index)}>
                                            <img src={item.url} alt={item.alternativeText} />

                                        </div>
                                        {GalleryImages.length === (index + 1) &&
                                            <button class="grey_nobg_btn" onClick={(e) => openLighboxMobile(e, 0)}>More Photos</button>
                                        }


                                     </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                {isOpen && (
                    <Lightbox
                        mainSrc={lightImages[photoIndex]}
                        nextSrc={lightImages[(photoIndex + 1) % lightImages.length]}
                        prevSrc={lightImages[(photoIndex + lightImages.length - 1) % lightImages.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + lightImages.length - 1) % lightImages.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % lightImages.length)
                        }
                    />
                )}
            </Container>
        </div>
    )

}
export default Gallery
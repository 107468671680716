import React, { useEffect, useState } from "react"
import { Button, Container, Row, Col, Card } from 'react-bootstrap'; 
import { isbot } from "isbot";
import HTMLReactParser from 'html-react-parser';  
import ScrollAnimation from 'react-animate-on-scroll'; 

const ContentBlock = ({ Content, Intro }) => {
  const [readMore, setReadMore] = useState(Content.length > 450 ? true : false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [userAgent, setUserAgent] = useState(null);

    const toggleDescContent = () => {
        setShowFullContent(!showFullContent);
        toggleReadMore();
    };
  const toggleReadMore = () => {
      setReadMore(!readMore);
    };
    useEffect(() =>{
      if(window && userAgent == null){
          setUserAgent(window?.navigator.userAgent)
      }
  },[])

  return (
    <div className={`contact-info`}>
      <Container>
        <Row>
          <Col xl={5}>  
              <h2>{Intro}</h2>
          </Col>
          <Col xl={7}>
              {Content &&
                <div className="animated">
                  <p className={` desc my-desc-content ${showFullContent || isbot(userAgent) === true ? 'full-content' : ''}`}>
                            <div dangerouslySetInnerHTML={{ __html: Content }} />
                         </p>
                    {Content.length > 450 &&
                             <span onClick={toggleDescContent} className="read-more-less">
                             {showFullContent || isbot(userAgent) === true ? ' - Read Less' : ' + Read More'}
                          </span> }</div>
              }  
          </Col>
        </Row> 
      </Container>
    </div>)
} 

export default ContentBlock
